<template>
    <b-modal id="modal" :title="data['name_' + lang]" no-close-on-backdrop
             no-close-on-esc
             size="lg"
             @close="onReset()"
    >
        <b-form v-if="data.id" id="form1" autocomplete="off" @reset="onReset">
            
            <b-row>
              <div class="col-12 mb-3">
                <b-button variant="primary" size="sm" @click="exportForm(data.id)" >
                  <b-icon icon="download"></b-icon>&nbsp;
                  {{ $t('preview.downloadBtn') }}
                </b-button>
              </div>
                <div class="col-12">
                    <label>{{$t('preview.stateLabel')}}</label>
                </div>

                    <div class="col-6">
                        <label>{{$t('preview.stateLabelAdmin')}}</label>
                        <div v-for="radio in states.admin"
                             :key="radio"
                              class="mb-2 full-btn btn "
                             :class="[ radio == data.status  ? 'btn-primary' : 'btn-outline-primary', role == 'read-only' ? 'disabled' : '' ]"
                              @click="setState(radio, data.id, 1)"
                              >{{$t('statesBtn.'+radio)}}</div>
                    </div>
                    <div class="col-6">
                        <b-form-group :label="$t('preview.stateLabelUser')" >
                            <b-form-radio v-for="radio in states.user"
                                          :value="radio"
                                          :key="radio"
                                          class="mb-2 full-btn disabled"
                                          v-model="data.status"
                                          button-variant="outline-primary"
                                          name="radios-btn-default"
                                          readonly=""
                                          button >{{$t('statesBtn.'+radio)}}</b-form-radio>
                        </b-form-group>
                    </div>
    
               
                <hr class="w-100">
                <div class="col-12">
                    <b-button v-b-toggle.collapse-1 variant="primary" @click="loadLogData()">{{$t('preview.logHistory')}}</b-button>
                    <b-collapse id="collapse-1" class="mt-2">
                        <table class="table">
                            <tr v-for="log in dataLog" :key="'log_'+log.id">
                                <td>{{log.date.date | moment("DD/MM/YYYY")}}<br>{{log.date.date | moment("HH:mm:ss")}}</td>
                                <td>{{$t('statesBtn.'+log.status)}}</td>
                            </tr>
                        </table>
                    </b-collapse>
                </div>
                <hr class="w-100">
                <div class="form-group col-12 mt-3">
                    <label>{{$t('projects.attachments')}}</label>
                    <local-file-manager
                        :id-foreign-table-row="data.id"
                    ></local-file-manager>
                </div>
                
            </b-row>
            
            
        </b-form>
        <template v-slot:modal-footer>
            <div class="w-100">
                <b-button type="reset" class="float-left" form="form1" variant="outline-secondary">
                    {{ $t('universalTxt.closeBtn') }}
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>

import LocalFileManager from "@/components/local-file-manager";
export default {
    name: "projects-preview",
    components: {LocalFileManager},
    data() {
        return {
            id: parseInt(this.$route.params.id),
            data: {},
            dataLog: {},
            states: this.$store.getters.states
        }
    },
    methods: {
        
        async loadData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/list.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {'id': this.id},
                    method: 'GET'
                });
                
                this.data = response.data[0];
                
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },
        async exportForm(projectId) {
          try {
            const response = await this.$http({
              url: process.env.VUE_APP_SERVER + 'api/articles/export-pdf.php',
              headers: {'content-type': 'application/x-www-form-urlencoded'},
              data: { id: projectId },
              method: 'POST'
            });

            const link = document.createElement('a');
            link.href = process.env.VUE_APP_SERVER + response.data.data;
            link.download = '';
            link.target = '__blank';
            link.click();


          } catch (error) {
            await this.loadData();
            console.log("error", error);
          }
        },
        async setState(status, id) {
            if (confirm(this.$t('preview.changeState'))) {
                try {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/articles/set-state.php',
                        headers: {'content-type': 'application/x-www-form-urlencoded'},
                        data: {
                            id: id,
                            status: status
                        },
                        method: 'POST'
                    });
    
                    this.$eventHub.$emit('openMessageController', 'success', this.$t('messages.success'));
                    await this.loadData();
    
                } catch (error) {
                    await this.loadData();
                    this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
                }
            }
        },
        async loadLogData() {
            try {
                const response = await this.$http({
                    url: process.env.VUE_APP_SERVER + 'api/articles/list_log.php',
                    headers: {'content-type': 'application/x-www-form-urlencoded'},
                    params: {'id': this.id},
                    method: 'GET'
                });
            
                this.dataLog = response.data;
            
            } catch (error) {
                console.log("error", error);
                this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
            }
        },

        onReset() {
            this.$emit('loadData');
            this.$router.go(-1);
        }
        
    },
    computed: {
        permissionsOptions: function () {
            return this.$store.getters.getUserRoles;
        },
        languages() {
            return this.$store.getters.langs
        },
        role: function () {
            try {
                return this.$store.getters.user.permission;
            } catch (err) {
                return false
            }
        },
        lang: function () {
          return this.$i18n.locale;
        }
    },
    mounted() {
        this.$bvModal.show('modal');
        this.loadData();
        this.loadLogData();
    },
    created() {
    
    }
}
</script>

<style lang="scss" >
    .form-group legend {
        font-weight: bold;
        font-size: 0.875rem;
    }
    .full-btn {
        width: 100%;
        label {
            width: 100%;
        }
        &.disabled {
            pointer-events: none;
        }
    }
</style>
